import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isTodayPlugin from 'dayjs/plugin/isToday';
import isYesterdayPlugin from 'dayjs/plugin/isYesterday';
import isTomorrowPlugin from 'dayjs/plugin/isTomorrow';
import relativeTime from 'dayjs/plugin/relativeTime';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/fr';
import duration from 'dayjs/plugin/duration';
import i18n from './i18n';

dayjs.extend(isSameOrAfter);
dayjs.extend(isYesterdayPlugin);
dayjs.extend(isTodayPlugin);
dayjs.extend(isTomorrowPlugin);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(duration);

/**
 *  ---- Dates -----
 *
 * Date/time related helpers functions
 */
export const getLocale = (language) => language.substr(0, 2);

/**
 * Format a date in English
 *
 * @param Date date
 * @param string format
 */
export const formatDate = (date, format) => dayjs(date).format(format);

/**
 * Format a date using the locale
 *
 * @param Date date
 * @param string format
 */
export const formatWithI18n = (date, format) =>
  dayjs(date).locale(getLocale(i18n.language)).format(format);

/**
 * Give an array of dates between 2 dates
 *
 * @param startDate Date object (included in the range)
 * @param stopDate Date object (included in the range)
 */
export const getDates = (startDate, stopDate) => {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(dayjs(currentDate));
    currentDate = dayjs(currentDate).add(1, 'day');
  }
  return dateArray;
};

/**
 * Show day relatively (localised)
 *
 * @param date The date to compare (string, object, etc)
 * @param format The format to output the day
 * @param lowerCase Whether or not the "Today" or "Tomorrow" strings should be in lower case
 * @param formatYesterday Whether or not to change yesterday's date to "Yesterday"
 */
export const getSmartDay = (
  date,
  format = 'ddd D MMM',
  lowerCase = false,
  formatYesterday = false
) => {
  const theDate = dayjs(date);
  if (theDate.isSame(dayjs(), 'day')) {
    return lowerCase
      ? i18n.t('generic.dates.today', 'Today').toLowerCase()
      : i18n.t('generic.dates.today', 'Today');
  }
  if (theDate.isSame(dayjs().add(1, 'day'), 'day')) {
    return lowerCase
      ? i18n.t('generic.dates.tomorrow', 'Tomorrow').toLowerCase()
      : i18n.t('generic.dates.tomorrow', 'Tomorrow');
  }

  if (formatYesterday && theDate.isSame(dayjs().subtract(1, 'day'), 'day')) {
    return lowerCase
      ? i18n.t('generic.dates.yesterday', 'Yesterday').toLowerCase()
      : i18n.t('generic.dates.yesterday', 'Yesterday');
  }

  return theDate.locale(getLocale(i18n.language)).format(format);
};

/**
 * Get time OR day + time if over a day (localised)
 * @param {*} date
 * @param {*} format
 */
export const getSmartTime = (date, format = 'H:mm') => {
  const theDate = dayjs(date);
  if (theDate.isSame(dayjs(), 'day')) {
    return theDate.locale(getLocale(i18n.language)).format(format);
  }
  return theDate.locale(getLocale(i18n.language)).format(`dddd ${format}`);
};

/**
 * isTimePast
 *
 * Check if a given time is past (for today)
 * @param {*} time
 */
export const isTimePast = (time) => {
  const t = dayjs(time, 'HH:mm:ss');
  return dayjs().hour(t.hour()).minute(t.minute()).isBefore(dayjs());
};

/**
 * isSameDay
 *
 * Check if two dates are the equal
 * If date2 is not given, it compares with today
 * @param {string} date1
 * @param {string} date2
 */
export const isSameDay = (date1, date2 = null) => {
  const d1 = dayjs(date1);
  const d2 = date2 ? dayjs(date2) : dayjs();
  return d1.isSame(d2, 'day');
};

/**
 * Format a given time
 * @param string time
 * @param bool twentyFourFormat 13:37 or 1:37pm
 */
export const formatTime = (time, twentyFourFormat = true) => {
  const theTime = dayjs(time, 'HH:mm:ss');
  return twentyFourFormat ? theTime.format('HH:mm') : theTime.format('H:mm A');
};

/**
 * Get the Date from delay
 * Used to get the delivery date adds one day if it falls on a sunday
 *
 * @param Date date Start from that date
 * @param {*} delay Number of days from now (1 = Tomorrow, 2 = Tuesday)
 *
 * @retun dayjs instance
 */
export const getDeliveryDateFromDelay = (date = dayjs(), delay) => {
  let dateWithDelay = dayjs(date).add(delay, 'minutes');
  if (dateWithDelay.day() === 0) {
    dateWithDelay = dateWithDelay.add(1, 'day');
  }
  return dateWithDelay;
};

/**
 * Get the day string from delay (e.g: Tomorrow, Wednesday)
 * Used to get the delivery day adds one day if it falls on a sunday
 *
 * @param Date date Start from that date
 * @param {*} delay in minutes
 */
export const getDeliveryDayFromDelay = (dateToConvert, delay, format = null) => {
  let date = dateToConvert;
  if (!date) {
    date = new Date();
  }
  let deliveryDay = dayjs(date).add(delay, 'minutes');
  if (deliveryDay.day() === 0) {
    deliveryDay = deliveryDay.add(1, 'day');
  }
  return getSmartDay(deliveryDay.locale(getLocale(i18n.language)), format);
};

/**
 * Get an invoice's Due Date based on it's creation date:
 *
 * @param Date invoice date
 */
export const getInvoiceDueDate = (date = new Date()) => {
  const plusSixWeeks = dayjs(date).add(6, 'week');
  const day = plusSixWeeks.get('day');

  // if it falls on a sunday
  if (day === 0) {
    return plusSixWeeks.add(1, 'day').format('YYYY-MM-DD');
  }

  if (day === 1) {
    return plusSixWeeks.format('YYYY-MM-DD');
  }

  // if it falls on other days
  return plusSixWeeks
    .endOf('week') // end of the week ib dayjs is saturday
    .add(2, 'day')
    .format('YYYY-MM-DD');
};

/**
 * Get the real dispatch date with its proper time:
 *
 * @param Date invoice date
 */
export const getDispatchDateFromOffer = (offer) => {
  if (!offer || !offer.route || !offer.route.collectionTime) {
    return null;
  }
  const dispatchTime = offer && offer.route && dayjs(offer.route.collectionTime);
  return dayjs(offer.dispatchDate)
    .set('hour', dispatchTime.hour())
    .set('minute', dispatchTime.minute());
};

export const sameOrAfter = (value, comparedTo, unit) => {
  if (!value) {
    throw new Error('provide a value date as 1st param');
  }

  if (!comparedTo) {
    throw new Error('provide a date to compare to as 2nd param');
  }

  return dayjs(value).isSameOrAfter(comparedTo, unit);
};

export const isWithin = (date, value, unit) => {
  const today = dayjs();
  const lastDay = today.add(value, unit);
  return dayjs(date).isBetween(today, lastDay, 'day', '[]');
};

export const isToday = (date) => dayjs(date).isToday();

export const isYesterday = (date) => dayjs(date).isYesterday();

export const isTomorrow = (date) => dayjs(date).isTomorrow();

export const timeFromNow = (date, now) =>
  dayjs(date, 'YYYY-MM-DDTHH:mm:ssZ').locale(i18n.language).from(dayjs(now));

export const timeTo = (date, now) =>
  dayjs(now).locale(i18n.language).to(dayjs(date, 'YYYY-MM-DDTHH:mm:ssZ'));
/**
 * Show day relatively (localised)
 *
 * @param {String} date The date to compare formatted YYYY-MM-DD
 * @returns {{ date: String }} { date: 2020-01-01 }
 */
export const getRolloverDates = (date = null, todayLastOrderDate = undefined) => {
  const today = dayjs();

  if (!date) {
    return {
      date: today.add(today.get('day') === 6 ? 2 : 1, 'day')
    };
  }

  const sourceDate = dayjs(date);

  if (sourceDate.isYesterday()) {
    // Today is before last order time AND today is not Sunday
    const isTodayAvailable = today.isBefore(todayLastOrderDate) && today.get('day') !== 0;
    const daysToAdd = isTodayAvailable ? 1 : 2;

    return {
      date: sourceDate.add(daysToAdd, 'day').format('YYYY-MM-DD')
    };
  }

  // Day of Week (Sunday as 0, Saturday as 6). If Saturday -> Rolls to Monday
  const daysToAdd = sourceDate.get('day') === 6 ? 2 : 1;
  const targetDate = sourceDate.add(daysToAdd, 'day');

  return {
    date: targetDate.format('YYYY-MM-DD')
  };
};

/**
 * Generate an array of dayjs dates object with
 * noOfDays length starting from today or custom date provided
 */
export const getDatesArray = (noOfDays = 1, from = undefined, includeSundays = false) => {
  const dates = [];
  const fromDate = dayjs(from);

  let totalDays = noOfDays;

  if (from && fromDate.get('day') === 0 && !includeSundays) {
    dates.push(fromDate.subtract(1, 'day').format('YYYY-MM-DD'));
    totalDays -= 1;
  }

  for (let i = 0; i < totalDays; i += 1) {
    const day = fromDate.add(i, 'day');
    if (day.get('day') !== 0 || (day.get('day') === 0 && includeSundays)) {
      dates.push(day.format('YYYY-MM-DD'));
    } else {
      totalDays += 1;
    }
  }

  return dates;
};

/**
 * Generate an array of dates ready to be used by our Reef select
 * @param {*} int noOfDays
 * @param {*} string from
 * @param {*} bool includeSundays
 * @returns Array
 */
export const getDateOptionsForSelect = (noOfDays = 1, from = undefined, includeSundays = false) =>
  getDatesArray(noOfDays, from, includeSundays).map((date) => ({
    value: date,
    label: getSmartDay(date)
  }));

/**
 * Generate an array of dates ready to be used by our Reef select until specified date
 * @param {*} int noOfDays
 * @param {*} string until
 * @param {*} bool includeSundays
 * @returns Array
 */
export const getDateOptionsForSelectUntil = (
  noOfDays = 1,
  until = undefined,
  includeSundays = false
) => {
  const dates = [];
  const fromDate = dayjs(until);

  let totalDays = noOfDays;

  if (until && fromDate.get('day') === 0 && !includeSundays) {
    dates.push(fromDate.subtract(1, 'day').format('YYYY-MM-DD'));
    totalDays -= 1;
  }

  for (let i = 0; i < totalDays; i += 1) {
    const day = fromDate.subtract(i, 'day');
    if (day.get('day') !== 0 || (day.get('day') === 0 && includeSundays)) {
      dates.push(day.format('YYYY-MM-DD'));
    } else {
      totalDays += 1;
    }
  }

  return dates.map((date) => ({
    value: date,
    label: getSmartDay(date)
  }));
};

/**
 * Sets time to 00:00:00 and returns date in a formatted string
 * @param {*} date Date
 * @param {*} format string
 * @returns string
 */
export const formatDateOnly = (date = new Date(), format = 'YYYY-MM-DD') =>
  dayjs(date).hour(0).minute(0).second(0).format(format);

export const isPastMiddayUTC = () => dayjs.utc().hour() >= 12;
